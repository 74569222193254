// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  api_url: 'https://lawhatyapidev.kortobaa.net/',
  name: 'dev',
  PLAY_STORE_URL:
    'https://play.google.com/store/apps/details?id=app.tamayoz.com&pli=1',
  APP_STORE_URL:
    'https://apps.apple.com/us/app/%D8%AA%D9%85%D9%8A-%D8%B2-tamayoz/id6550917117',
  HUAWEI_STORE_URL: 'https://appgallery.huawei.com/app/',
  APP_SCHEMA: 'lawhatydev',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
